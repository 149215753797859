
const Map = () => {
  return (
    <section className="row tm-section">
        <div className="mapouter">
            <div className="gmap_canvas">
                <iframe width="100%" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=Kotoka%20&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                <a href="https://www.whatismyip-address.com"></a>
                <br />        
                <a href="https://www.embedgooglemap.net">google maps html generator</a>
            </div>
        </div>
    </section>   
  )
}

export default Map