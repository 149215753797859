import Map from '../components/Map'
import { FaEnvelope } from 'react-icons/fa'
import { Helmet } from 'react-helmet-async'

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact</title>
        <meta name='description' 
        content='Contact Us For More Information.' />
        <link rel='canonical' href='/contact' />
      </Helmet>
      <div className="main-content-elements">
          <div className="sub-page-headers contact-pg">
              <div>
                <div className='contact-desc-panel'>
                  <h1>LEAVE YOUR COMMENT</h1>
                  <p>No matter what your business needs are, we understand what's 
                    important to you. Our passion is to delight our clients with first class 
                    solutions and deliver value to them. Please contact us with your requirements, 
                    enquiries, feedback etc. 
                  </p>
                  <p>Email:info@primusglobalgh.com</p>
                </div>
                <div className='contact-form'>
                    <form className='form'>
                      <div className='form-group'>
                        <input type='text' placeholder='Name' />
                      </div>
                      <div className='form-group'>
                        <input type='email' placeholder='Email' />
                      </div>
                      <div className='form-group'>
                        <input type='text' placeholder='Phone' />
                      </div>
                      <div className='form-group'>
                        <textarea placeholder='Message' rows='5' />
                      </div>

                      <div className='form-group'>
                        {/* <input type='submit' className='btn contact-btn' value='Send' /> */}
                        <button type='submit' className='btn contact-btn'>
                          <span><FaEnvelope /></span>
                          Send
                        </button>
                      </div>
                    </form>
                </div>
              </div>
          </div>
          <div className="sub-page-content">
            <section className='sub-page-content-elements'>
              <div className='contact-details'>
                <h2 className='tm-color-primary'>TALK TO US</h2>
                <p>If you have any questins or need help, feel free to contact our team.</p>
                <p>OPEN HOURS</p>
                <p>Mon-Fri 8:30am - 5:00pm GMT</p>
              </div>
              <div className='pg-countries-details'>
                <div className='ghanabr'>
                  <h3 className='tm-color-primary'>GHANA BRANCH</h3>
                  Cargo D'or, Room #2 <br />
                  Kotoka International Airport<br />
                  Accra<br />
                  Ghana<br /><br />

                  P.O. Box CT<br />
                  Cantonment, Accra<br />
                  Ghana<br /><br />

                  GH Tel: +233 307 013382<br />
                  GH Mob: +233 262 626139<br />
                  GH Mob: +233 244 860320
                </div>
                <div className='beninbr'>
                  <h3 className='tm-color-primary'>BENIN BRANCH</h3>
                  BN Mob: +229 510 59999<br />
                  BN Mob: +299 900 60642 
                </div>
              </div>
            </section>
          </div>
          <Map />
      </div>
    </>
  )
}

export default Contact