import GTP from '../assets/images/Clients/Gtp.png'
import POM from '../assets/images/Clients/Promasidor.png'
import MAN from '../assets/images/manet.jpeg'
import SPA from '../assets/images/Clients/Spaklean.jpg'
import UNF from '../assets/images/Clients/UniqueFloral.png'
import Slider from 'react-slick'

const Clients = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section id='clients' className='row tm-section'>
      <div className='react-sl-sl'>
        <div>
          <h2 className="tm-color-primary tm-section-title mb-3">OUR CLIENTS</h2>
          <p className="mb-3">
            At PGL, our hallmark is client satisfaction. To live up to this, we offer full logistics solutions in ways that relieve out clients of all the stress associated with moving goods from one place to another, through all known mediums. 
          </p>
        </div>
        <Slider {...settings}>
          <div>
            <img src={GTP} alt="Client Image" className="img-fluid tm-client-img" />
          </div>
          <div>
          <img src={POM} alt="Client Image" className="img-fluid tm-client-img" />
          </div>
          <div>
          <img src={MAN} alt="Client Image" className="img-fluid tm-client-img" />
          </div>
          <div>
          <img src={SPA} alt="Client Image" className="img-fluid tm-client-img" />
          </div>
          <div>
          <img src={UNF} alt="Client Image" className="img-fluid tm-client-img" />
          </div>
        </Slider>
      </div>
    </section>
  )
}

export default Clients