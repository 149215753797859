
const Destinations = () => {
  return (
    <div className="card-rows destinations">
        <div>
            <h2 className="tm-section-title tm-color-primary mb-2">RWANDAIR DESTINATIONS</h2>
        </div>
        <div className="row-destinations">    
            <div className="col">London (LGW)</div>
            <div className="col">London (LHR)</div>
            <div className="col">Accra</div>
            <div className="col">Nairobi</div>
            <div className="col">Abidjan</div>
            <div className="col">Mumbai</div>
            <div className="col">Juba</div>
            <div className="col">Mombasa</div>
            <div className="col">Libreville</div>
            <div className="col">Brazzaville</div>
            <div className="col">Cotonou</div>
            <div className="col">Dubai</div>
            <div className="col">Lusaka</div>
            <div className="col">Dar Es Salam</div>
            <div className="col">Douala</div>
            <div className="col">Kamembe</div>
            <div className="col">Entebbe</div>
            <div className="col">Kampala</div>
            <div className="col">Brussels</div>
            <div className="col">Harare</div>
            <div className="col">Kilimanjaro</div>
            <div className="col">Johannesburg</div>
            <div className="col">Lagos</div>
            <div className="col">Bujumbura</div>
        </div>
    </div>
  )
}

export default Destinations