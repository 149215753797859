import { BrowserRouter as Router } from 'react-router-dom';
import MainContent from './components/MainContent'
import MenuPanel from './components/MenuPanel'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <div className="app-container">     
        <Router basename='/'>   
          <MenuPanel />   
          <MainContent />
        </Router>
        <div className="elfsight-app-e353ecfb-3844-496b-8826-b23227eb1854"></div>
    </div>
  );
}

export default App;
