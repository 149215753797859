import { useState } from "react"
import {Link} from "react-router-dom"
import { FaPhoneSquare, FaEnvelopeSquare, FaFacebookF, 
  FaTwitter, FaLinkedinIn, FaBars } from "react-icons/fa"

const MenuPanel = () => {
  const [sidePanel, setSidePanel] = new useState(document.querySelector('.sidepanel'))
  
  const openNav = (e) => {
    // console.log(e.currentTarget.classList)
    // const clist = document.getElementById("sidepanel")
    // clist.classList.contains("show")? clist.classList.replace("show", "hide") 
    // : clist.classList.replace("hide", "show")
    //var width = window.innerWidth
    // || document.documentElement.clientWidth
    // || document.body.clientWidth;

    // var height = window.innerHeight
    // || document.documentElement.clientHeight
    // || document.body.clientHeight;

    const getCurrentWidth = document.querySelector('.sidepanel')
    const newWidth = getCurrentWidth.style.left === "0px"? "-310px" : "0px"
    setSidePanel(newWidth)
  }

  return (
    <div id="sidepanel" className="sidepanel" style={{ left:sidePanel }}>
      <button onClick={openNav} className="openbtn" type="button" 
      aria-label="Toggle navigation">
        <FaBars />
      </button>

      <div className="sidepanel-sticky">
        <div className="site-contact-num-sec">
          <span>Ghana:&nbsp;<i><FaPhoneSquare /></i>&nbsp;(+233) 59-4673038</span>
          <span>Benin:&nbsp;<i><FaPhoneSquare /></i>&nbsp;(+229) 51-059999</span>
          <span>Email:&nbsp;<i><FaEnvelopeSquare /></i>&nbsp;info@primusglobalgh.com</span>
        </div>
        <div className="site-logo-sec"></div>
        <ul className="nav text-uppercase tm-main-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                <span>Home</span>
                <span className="tm-white-rect"></span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link">
                <span>About</span>
                <span className="tm-white-rect"></span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/services" className="nav-link">
                <span>Services</span>
                <span className="tm-white-rect"></span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/fleet" className="nav-link">
                <span>Fleet</span>
                <span className="tm-white-rect"></span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">
                <span>Contact</span>
                <span className="tm-white-rect"></span>
              </Link>
            </li>
        </ul>
        <section>
          <ul className="nav flex-row tm-social-links">
            <li className="nav-item">
              <a target="_blank" href="https://m.facebook.com/Primus-Global-Limited-116858410168913/" className="nav-link tm-social-link">
                <FaFacebookF />
              </a>
            </li>
            <li className="nav-item">
              <a href="#" target="_blank" className="nav-link tm-social-link">
                <FaTwitter />
              </a>
            </li>
            <li className="nav-item">
              <a target="_blank" href="#" className="nav-link tm-social-link">
                <FaLinkedinIn />
              </a>
            </li>
          </ul>
        </section>
        <footer>
          <p>&copy; 2022 | All Rights Reserved</p>
          <p>Design:
            <a href="http://promaxgh.com">Promax Computers</a>
          </p>
        </footer>
      </div>
    </div>
  ) 
}

export default MenuPanel