const SidePanel = () => {
  return (
    <div className="right-side-panel">
        <div className="top-square">
          <section>
            <p>Air Cargo Handling</p>
            <p>Freight Forwarding</p>
            <p>Customs Brokerage</p>
          </section>
          <section className="vertical-line">
            <div className="vertical-line-circle"></div>
          </section>
          <section>
            <p>Home Moving</p>
            <p>Cargo Clearing</p>
            <p>Supply Chain Mgmt.</p>
          </section>
        </div>
        <div className="circle big-circle"></div>
        <div className="trapezoid"></div>
        <div className="circle small-circle circle-top"></div>
        <div className="circle small-circle circle-bottom"></div>
        <div className="dots dots-top"></div>
        <div className="dots dots-bottom"></div>
        <div className="bottom-square"></div>
    </div>
  )
}

export default SidePanel