import { Helmet } from 'react-helmet-async'
import HomeVideo from '../assets/videos/PHBanner.mp4'
import Plane from '../assets/images/plane.jpg'
import RightSidePanel from '../components/RightSidePanel'

const Home = () => {
    window.onload = function() {
        document.getElementById("autoplay").play()
    }
  return (
    <>
        <Helmet>
          <title>Home</title>
          <meta name='description' 
          content='GSA Cargo, RwandAir Cargo, Customs Clearance, Storage, Logistics, Freight Forwarder, Air Cargo, Sea Cargo, Warehousing Services.' />
          <link rel='canonical' href='/' />
        </Helmet>
        <video loop muted id="autoplay" autoPlay={true} className='bannervid' poster={Plane}>
            <source src={HomeVideo} type="video/mp4" />
            Your browser does not support video tag.
        </video>
        <div className='home-right-panel'>
          <RightSidePanel />
        </div>  
    </>
  )
}

export default Home