import P1 from '../assets/data1/images/P1.jpg'
import P2 from '../assets/data1/images/P2.jpg'
import P3 from '../assets/data1/images/P3.jpg'
import P4 from '../assets/data1/images/P4.jpg'
import P5 from '../assets/data1/images/P5.jpg'
import P6 from '../assets/data1/images/P6.jpg'
import P7 from '../assets/data1/images/P7.jpg'

const SubPageBanner = () => {
  return (
    <div id="wowslider-container1">
      <div className="ws_images">
        <ul>
          <li>
            <img src={P1} alt="P1" title="Welcome to Primus Global" id="wows1_0"/>
            <div>
              <p>Get your items delivered at your door step.</p>
            </div>
          </li>
          <li>
            <img src={P2} alt="P2" title="Welcome to Primus Global" id="wows1_1"/>
            <div> 
              <p>Container Clearing and Shipment made easy by Primus</p>
            </div>
          </li>
          <li>
            <img src={P3} alt="P3" title="Welcome to Primus Global" id="wows1_2"/>
            <div>
              <p>Safe and Reliable Freight Services</p>
            </div>
          </li>
          <li>
            <img src={P4} alt="P4" title="P4" id="wows1_3"/>
            <div>
              <p>Road movement to any place of your choice</p>
            </div>
          </li>
          <li>
            <img src={P5} alt="P5" title="P5" id="wows1_4"/>
            <div>
              <p>Getting our Agents to safely deliver your item</p>
            </div>
          </li>
          <li>
            <img src={P6} alt="P6" title="P6" id="wows1_5"/>
            <div>
              <p>Getting our Agents to safely deliver your item</p>
            </div>
          </li>
          <li>
            <img src={P7} alt="P7" title="P7" id="wows1_6"/>
            <div>
              <p>International logistics company operating in Ghana</p>
            </div>
          </li>
        </ul>
      </div>
      <div className="ws_shadow"></div>
    </div>	
  )
}

export default SubPageBanner